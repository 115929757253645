import { Navigate, Route, Routes } from "react-router-dom"
import LandingPage from "./Pages/WebPage/LandingPage"
import Login from "./Pages/Auth/Login"
import Register from "./Pages/Auth/Register"
import BaseLayout from "./components/Layouts/BaseLayout"
import PlaceOrder from "./Pages/WebPage/Order/PlaceOrder"
import Vehicles from "./Pages/Supplier/Vehicles/Vehicles"
import NewRequest from "./Pages/Client/OrderRequest/NewRequest"
import Details from "./Pages/Client/OrderRequest/Details"
import History from "./Pages/Supplier/OrderHistory/History"
import OderHistory from "./Pages/Client/OrderHistory/History"
import UserTransaction from "./Pages/Client/Transaction/Transaction"
import Transaction from "./Pages/Supplier/Transaction/Transaction"
import ClientSupport from "./Pages/Supplier/Support/Support"
import Bill from "./Pages/Client/PaymentBill/Bill"
import AdminHistory from "./Pages/Admin/OrderHistory/History"
import AdminTransaction from "./Pages/Admin/Transaction/Transaction"
import AdminVehicles from "./Pages/Admin/Vehicles/Vehicles"
import AdminGasPricing from "./Pages/Admin/GasPricing/GasPricing"
import AdminDeliveryPricing from "./Pages/Admin/DeliveryPricing/DeliveryPricing"
import Support from "./Pages/Client/Support/Support"
import AdminSupport from "./Pages/Admin/Support/Support"
import ForgotPassword from "./Pages/Auth/ForgotPassword"
import ResetPassword from "./Pages/Auth/ResetPassword"
import VerifyEmail from "./Pages/Auth/VerifyEmail"
import EmailVerified from "./Pages/Auth/EmailVerified"
import Profile from "./Pages/Profile/Profile"
import Suppliers from "./Pages/Admin/Users/Suppliers/Supplier"
import Customers from "./Pages/Admin/Users/Customers/Customer"
import useAuth from "./hooks/useAuthContext"
import { Requests } from "./Pages/Supplier/GasRequests/Requests"
import BankAccounts from "./Pages/Supplier/Payment/BankAccounts"
import Index from "./Pages/Dashboard/Index"
import PendingOrders from "./Pages/Supplier/OrderHistory/PendingOrders"
import OrderPaymentAccounts from "./Pages/Admin/Payment/OrderPaymentAccounts"

const AppRoutes = () => {
    const {userData}  = useAuth()
    return (
        <Routes>
            <Route path="/">
                <Route index element={<Navigate to="/home" />} />
                <Route path="order" element={<PlaceOrder />} />
                <Route path="auth">
                    <Route path="login/:usertype?" element={userData && userData.userInfo ? <Navigate to="/app/dashboard" /> : <Login />} />
                    <Route path="register/:usertype?" element={userData && userData.userInfo ? <Navigate to="/app/dashboard" /> : <Register />} />
                    <Route path="forgot-password" element={  userData && userData.userInfo ? <Navigate to="/app/dashboard" /> : <ForgotPassword />} />
                    <Route path="reset-password" element={userData && userData.userInfo ? <Navigate to="/app/dashboard" /> : <ResetPassword />} />
                    <Route path="verify-email" element={<VerifyEmail />} />
                    <Route path="email-verified" element={<EmailVerified />} />
                </Route>
                <Route path="app">
                    <Route path="dashboard" element={userData && userData.userInfo ? <BaseLayout><Index /></BaseLayout> : <Navigate to="/auth/login" />} />
                    <Route path="profile" element={<BaseLayout><Profile /></BaseLayout>} />
                    <Route path="user">
                        <Route path="order" element={<BaseLayout><NewRequest /></BaseLayout>}></Route>
                        <Route path="history" element={<BaseLayout><OderHistory /></BaseLayout>}></Route>
                        <Route path="order/details/:orderId?" element={userData && userData.userInfo ?
                            <BaseLayout><Details /></BaseLayout>
                            :
                            <Navigate to="/auth/login" />
                            }></Route>
                        <Route path="order/details/invoice/:orderId" element={<BaseLayout><Bill /></BaseLayout>}></Route>
                        <Route path="transaction/history" element={<BaseLayout><UserTransaction /></BaseLayout>}></Route>
                        <Route path="contact" element={<BaseLayout><Support/></BaseLayout>}></Route>
                    </Route>
                    <Route path="supplier">
                        <Route path="dashboard" element={<BaseLayout><Index /></BaseLayout>} />
                        <Route path="vehicles" element={<BaseLayout><Vehicles /></BaseLayout>} />
                        <Route path="history" element={<BaseLayout><History /></BaseLayout>} />
                        <Route path="history/pending" element={<BaseLayout><PendingOrders /></BaseLayout>} />
                        <Route path="order" element={<BaseLayout><Requests /></BaseLayout>} />
                        <Route path="transaction" element={<BaseLayout><Transaction /></BaseLayout>} />
                        <Route path="contact" element={<BaseLayout><ClientSupport /></BaseLayout>} />
                        <Route path="payment" element={<BaseLayout><BankAccounts /></BaseLayout>} />
                    </Route>
                    <Route path="admin">
                        <Route path="dashboard" element={<BaseLayout><Index /></BaseLayout>} />
                        <Route path="history" element={<BaseLayout><AdminHistory /></BaseLayout>}></Route>
                        <Route path="transaction" element={<BaseLayout><AdminTransaction /></BaseLayout>}></Route>
                        <Route path="vehicles" element={<BaseLayout><AdminVehicles /></BaseLayout>}></Route>
                        <Route path="gas-pricing" element={<BaseLayout><AdminGasPricing /></BaseLayout>}></Route>
                        <Route path="delivery-pricing" element={<BaseLayout><AdminDeliveryPricing /></BaseLayout>}></Route>
                        <Route path="contact" element={<BaseLayout><AdminSupport /></BaseLayout>}></Route>
                        <Route path="payment" element={<BaseLayout><OrderPaymentAccounts /></BaseLayout>} />
                        <Route path="users">
                            <Route path="supplier" element={<BaseLayout><Suppliers /></BaseLayout>}></Route>
                            <Route path="customer" element={<BaseLayout><Customers /></BaseLayout>}></Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="home" element={<LandingPage />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes