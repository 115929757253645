import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuthContext";

export default function AppSidebar() {
  const {userData} = useAuth()

  const myDivRef = useRef(null);
  const myAnchorRef = useRef(null);

  const url = window.location.href

  useEffect(() => {
    if (myDivRef.current) {
      myDivRef.current.classList.remove('show');
      myAnchorRef.current.classList.add('collapsed');
    }
  }, [url])

  return (
    <>
      {/* <!-- ======= Sidebar ======= --> */}
      <aside id="sidebar" className="sidebar app-sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          {userData && userData?.userInfo?.user_type == 'user' 
          
          ?
          <>
            {/* <!-- ======= User Menu ======= --> */}
            <li className="nav-heading">User</li>
            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/dashboard">
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <!-- End Dashboard Nav --> */}

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#user-order-nav"
                data-bs-toggle="collapse"
                ref={myAnchorRef}
                href="#"
              >
                <i className="bi bi-minecart"></i>
                <span>Orders</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="user-order-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
                ref={myDivRef}
              >
                <li>
                  <NavLink to="/app/user/order">
                    <i className="bi bi-fire"></i>
                    <span>Gas Request</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link collapsed" to="/app/user/history">
                    <i className="bi bi-truck"></i>
                    <span>History</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link collapsed"
                to="/app/user/transaction/history"
              >
                <i className="bi bi-truck"></i>
                <span>Transaction</span>
              </NavLink>
            </li>
            {/* <!-- End Order Request Nav --> */}

            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/dashboard">
                <i className="bi bi-credit-card"></i>
                <span>Payments</span>
              </NavLink>
            </li>
            {/* <!-- End Payments Nav --> */}

            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/user/contact">
                <i className="bi bi-headset"></i>
                <span>Support</span>
              </NavLink>
            </li>
            {/* <!-- End Support Nav --> */}


            {/* <!-- ======= End User Menu ======= --> */}
          </>

        :

        userData && userData?.userInfo?.user_type == 'supplier' ? 
          <>
            {/* <!-- ======= Supplier Menu ======= --> */}
            <li className="nav-heading">Supplier</li>
            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/dashboard">
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <!-- End Dashboard Nav --> */}

            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/supplier/vehicles">
                <i className="bi bi-truck"></i>
                <span>Vehicles</span>
              </NavLink>
            </li>
            {/* <!-- End Vehicles Nav --> */}

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#supplier"
                data-bs-toggle="collapse"
                ref={myAnchorRef}
                href="#"
              >
                <i className="bi bi-minecart-loaded"></i>
                <span>Orders</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="supplier"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
                ref={myDivRef}
              >
                <li>
                  <NavLink to="/app/supplier/order">
                    <i className="bi bi-fire"></i>
                    <span>New Requests</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/supplier/history/pending">
                    <i className="bi bi-fire"></i>
                    <span>Pending Requests</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link collapsed"
                    to="/app/supplier/history"
                  >
                    <i className="bi bi-truck"></i>
                    <span>History</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <!-- End Order Request Nav --> */}

            <li className="nav-item">
              <NavLink
                className="nav-link collapsed"
                to="/app/supplier/transaction"
              >
                <i className="bi bi-truck"></i>
                <span>Transaction</span>
              </NavLink>
            </li>
            {/* <!-- End Transaction Nav --> */}

            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/supplier/payment">
                <i className="bi bi-credit-card"></i>
                <span>Payments</span>
              </NavLink>
            </li>
            {/* <!-- End Payments Nav --> */}

            <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/supplier/contact">
                <i className="bi bi-headset"></i>
                <span>Support</span>
              </NavLink>
            </li>
            {/* <!-- End Support Nav --> */}

            {/* <!-- ======= End Supplier Nav ======= --> */}
          </>
        :

          <>
          {/* <!-- ======= Super Admin Menu ======= --> */}
          <li className="nav-heading">Admin</li>

          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/app/admin/dashboard">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
          {/* <!-- End Dashboard Nav --> */}

          <li className="nav-item">
          <NavLink className="nav-link collapsed" to="/app/admin/vehicles">
              <i className="bi bi-grid"></i>
              <span>Vehicles</span>
            </NavLink>
          </li>
          {/* <!-- End Vehicles Nav --> */}

          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/app/admin/history">
              <i className="bi bi-grid"></i>
              <span>Order History</span>
            </NavLink>
          </li>
          {/* <!-- End Order Nav --> */}

          <li className="nav-item">
          <NavLink className="nav-link collapsed" to="/app/admin/transaction">
              <i className="bi bi-grid"></i>
              <span>Transaction History</span>
            </NavLink>
          </li>
          {/* <!-- End Transaction Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#forms-nav"
              data-bs-toggle="collapse"
              href="#"
              ref={myAnchorRef}
            >
              <i className="bi bi-person-plus"></i>
              <span>Users</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="forms-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
              ref={myDivRef}
            >
              <li>
                <NavLink to="/app/admin/users/supplier">
                  <i className="bi bi-person"></i>
                  <span>Suppliers</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admin/users/customer">
                  <i className="bi bi-person"></i>
                  <span>Customer</span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/app/admin/contact">
              <i className="bi bi-headset"></i>
              <span>Support</span>
            </NavLink>
          </li> */}
          {/* <!-- End Users Nav --> */}

          <li className="nav-item">
              <NavLink className="nav-link collapsed" to="/app/admin/payment">
                <i className="bi bi-credit-card"></i>
                <span>Payments</span>
              </NavLink>
            </li>
            {/* <!-- End Payments Nav --> */}

          <li className="nav-item">
          <NavLink className="nav-link collapsed" to="/app/admin/gas-pricing">
              <i className="bi bi-currency-exchange"></i>
              <span>Gas Pricing</span>
            </NavLink>
          </li>
          
          <li className="nav-item">
          <NavLink className="nav-link collapsed" to="/app/admin/delivery-pricing">
              <i className="bi bi-currency-exchange"></i>
              <span>Delivery Pricing</span>
            </NavLink>
          </li>

          
          {/* <!-- End Profile Page Nav --> */}
          </>
        }
        </ul>
      </aside>
      {/* <!-- End Sidebar--> */}
    </>
  );
}
